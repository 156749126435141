<template>
  <form-builder :schema="schema" />
</template>

<script>
export default {
  name: 'IntegrationShopMatrixRow',
  emits: ['change', 'delete'],
  props: {
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    },
    values: {
      type: Object,
      default () {
        return {
          ourShops: [],
          extShops: []
        }
      }
    }
  },
  data () {
    return {
      shops: [],
      extShops: []
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Shop'),
                wrapperStyleClasses: 'col q-pa-xs',
                value: this.model.id,
                customLabel: (row) => {
                  if (row) {
                    if (typeof row === 'object') {
                      return `${row.name || this.$t('No name')} (${row.id})`
                    }

                    let shop = this.shops.find(x => x.id == row)

                    if (!shop) {
                      shop = this.values.ourShops.find(x => x.id == row)
                    }

                    if (shop) {
                      return `${shop.name || this.$t('No name')} (${shop.id})`
                    }

                    return row
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active','blocked'] }
                    ]
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: (value) => {
                  if (value) {
                    this.shops.push(value)
                  } else {
                    this.shops = []
                  }

                  this.$emit('change', { ...this.model, id: value && value.id })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('External shop'),
                wrapperStyleClasses: 'col q-pa-xs',
                value: this.model.extId,
                customLabel: (row) => {
                  if (row) {
                    if (typeof row === 'object') {
                      return `${row.name || this.$t('No name')} (${row.id})`
                    }

                    let shop = this.extShops.find(x => x.id == row)

                    if (!shop) {
                      shop = this.values.extShops.find(x => x.id == row)
                    }

                    if (shop) {
                      return `${shop.name || this.$t('No name')} (${shop.id})`
                    }

                    return row
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.dictionary.getShops(this.source.id, query)
                },
                onChange: (value) => {
                  if (value) {
                    this.extShops.push(value)
                  } else {
                    this.extShops = []
                  }

                  this.$emit('change', { ...this.model, extId: value && value.id })
                }
              },
              {
                type: 'btn',
                label: this.$t('Delete'),
                wrapperStyleClasses: 'q-pa-xs',
                size: 'lg',
                btnStyleClasses: 'fit',
                onClick: () => {
                  this.$emit('delete', this.model)
                }
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
