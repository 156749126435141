<template>
  <div
    class="q-mt-md"
    style="width: 100%;"
  >
    <form-builder :schema="schema" />

    <integration-shop-matrix
      v-if="shopMatrix"
      :model="model"
      @change="handleChange"
    />
  </div>
</template>

<script>
// Components
import IntegrationShopMatrix from './IntegrationShopMatrix.vue'

export default {
  name: 'IntegrationTab',
  emits: ['change'],
  components: {
    IntegrationShopMatrix
  },
  props: {
    tab: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    shopMatrix () {
      return this.tab.attributes.find(x => x.type === 'Orderadmin\\Application\\Form\\Element\\ParamsMatrix' && x.name === 'shops')
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: this.tab.attributes.reduce((acc, field) => {
              const newField = this.$utils.field.create(this.model.settings, { ...field, name: `${this.tab.name}.${field.name}`})

              if (!newField) {
                return acc
              }

              newField.watch = () => {
                this.handleChange(this.model)
              }

              return [...acc, newField]
            }, [])
          }
        ]
      }
    }
  },
  methods: {
    handleChange (data) {
      this.$emit('change', data)
    }
  }
}
</script>
