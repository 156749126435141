<template>
  <div
    v-if="isLoading > 0"
    class="row fit justify-center items-center"
    style="min-height: 200px;"
  >
    <q-spinner
      color="light-blue-9"
      size="3rem"
    />
  </div>

  <div v-else class="text-center">
    <integration-shop-matrix-row
      v-for="(row, i) in shops"
      :key="i"
      :model="row"
      :source="model"
      :values="values"
      @change="handleChange($event, i)"
      @delete="handleDelete(i)"
    />

    <div v-if="shops.length <= 0" class="text-subtitle1 q-pa-sm">
      {{ $t('No shops') }}
    </div>

    <div class="q-pa-sm">
      <q-btn
        color="light-blue-9"
        icon="add"
        @click="handleAddRow"
      />
    </div>
  </div>
</template>

<script>
// Components
import IntegrationShopMatrixRow from './IntegrationShopMatrixRow.vue'

// Utils
import _ from 'lodash'

export default {
  name: 'IntegrationSettings',
  emits: ['change'],
  components: {
    IntegrationShopMatrixRow
  },
  props: {
    integration: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isLoading: 0,
      values: {
        ourShops: [],
        extShops: []
      }
    }
  },
  computed: {
    shops () {
      if (this.model.settings && this.model.settings.Products && this.model.settings.Products.shops) {
        if (Array.isArray(this.model.settings.Products.shops)) {
          return this.model.settings.Products.shops
        }
      }

      return []
    }
  },
  mounted () {
    if (this.shops.length > 0 && this.values.ourShops.length <= 0) {
      const shops = this.shops.reduce((acc, item) => {
        if (!item) {
          return acc
        }

        if (item.id) {
          acc.our.push(item.id)
        }

        if (item.extId) {
          acc.ext.push(item.extId)
        }

        return acc
      }, { our: [], ext: [] })

      if (shops.our.length > 0) {
        this.loadOurShops(shops.our)
      }

      if (shops.ext.length > 0) {
        this.loadExtShops(shops.ext)
      }
    }
  },
  methods: {
    loadOurShops (values) {
      const query = {
        page: 1,
        per_page: 250,
        filter: [
          { type: 'in', field: 'id', values }
        ]
      }

      this.isLoading++

      return this.$service.shop.getAll(query)
        .then(({ items }) => {
          this.values = {
            ...this.values,
            ourShops: items
          }
        })
        .finally(() => {
          this.isLoading--
        })
    },
    loadExtShops (values) {
      const query = {
        page: 1,
        per_page: 250,
        filter: [
          { type: 'in', field: 'id', values }
        ]
      }

      this.isLoading++

      return this.$service.dictionary.getShops(this.model.id, query)
        .then(({ items }) => {
          this.values = {
            ...this.values,
            extShops: items
          }
        })
        .finally(() => {
          this.isLoading--
        })
    },
    handleChange (change, index) {
      const model = _.cloneDeep(this.model)
      model.settings.Products.shops = this.shops.map((x, i) => i === index ? change : x)

      this.$emit('change', model)
    },
    handleDelete (index) {
      const model = _.cloneDeep(this.model)
      model.settings.Products.shops = this.shops.filter((x, i) => i !== index)

      this.$emit('change', model)
    },
    handleAddRow () {
      const model = _.cloneDeep(this.model)
      model.settings.Products.shops = [...this.shops, { id: null, extId: null, state: 'active' }]

      this.$emit('change', model)
    }
  }
}
</script>
